<template>
  <div id="container">

    <section id="login">
      <div class="wrap">
        <h2 class="cts_ttl"><span>パスワードを再設定する</span></h2>
        <p class="alnC">再設定するご希望のパスワードを<span class="red bold">8文字以上の半角英数記号</span>で入力してください。</p>
        <div class="cts_box">
          <form @submit="submitClicked">
            <div class="reset_pswd">
              <div class="input_set">
                <div class="label">新しいパスワード<span class="caution">（8文字以上の半角英数記号）</span></div>
                <div class="pswd_box flex">
                  <input v-bind:type="password_type ? 'text' : 'password'" v-model="password" class="password"
                         @input="password = replaceFullToHalf(password)"
                         v-bind:class="{error: validation_errors.password}"
                         placeholder="8文字以上">
                  <div class="checkbox">
                    <input id="check01" type="checkbox" v-model="password_type" value="1">
                    <label for="check01">入力内容を表示</label>
                  </div>
                </div>
                <validation-errors :errors="validation_errors.password"
                                   v-if="validation_errors.password"></validation-errors>
              </div>
              <div class="input_set">
                <div class="label">確認のためにもう一度入力<span class="caution">（8文字以上の半角英数記号）</span></div>
                <div class="pswd_box flex">
                  <input v-bind:type="password_confirmation_type ? 'text' : 'password'" v-model="password_confirmation"
                         @input="password_confirmation = replaceFullToHalf(password_confirmation)"
                         v-bind:class="{error: validation_errors.password_confirmation}"
                         class="password" placeholder="8文字以上">
                  <div class="checkbox">
                    <input id="check02" type="checkbox" v-model="password_confirmation_type" value="1">
                    <label for="check02">入力内容を表示</label>
                  </div>
                </div>
                <validation-errors :errors="validation_errors.password_confirmation"
                                   v-if="validation_errors.password_confirmation"></validation-errors>
              </div>
            </div>

            <p class="alnC mb20">新しいご希望のパスワードを入力後こちらのボタンを押してください。</p>
            <div class="button_wrap">
              <input type="submit" class="submit light arrow" value="パスワードを再設定する">
            </div>
          </form>
        </div>
      </div><!--//.wrap-->
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import ValidationErrors from '@/components/ValidationErrors.vue'

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors
  },
  data() {
    return {
      password: null,
      password_confirmation: null,
      password_type: false,
      password_confirmation_type: false,
      validation_errors: {},
      errors: null,
    }
  },
  mounted() {
    //
  },
  created() {
    //
  },
  methods: {
    submitClicked(event) {
      event.preventDefault()

      this.errors = null
      this.validation_errors = {}
      this.axios
          .post(`${this.env.api_base_url}users/update.json`, {
            reset_password_token: this.$route.params.reset_password_token,
            user: {
              password: this.password,
              password_confirmation: this.password_confirmation,
              password_validates: true
            }
          })
          .then(() => {
            this.$router.push({
              name: 'UsersPasswordEditFinish'
            })
          })
          .catch((error) => {
            this.scrollTop()
            if (error.response.data.errors) {
              if (error.response.data.validation_errors.password) {
                this.validation_errors.password = error.response.data.validation_errors.password
              }
              if (error.response.data.validation_errors.password_confirmation) {
                this.validation_errors.password_confirmation = error.response.data.validation_errors.password_confirmation
              }
              this.$forceUpdate();
            }
          });
    }
  }
}
</script>
