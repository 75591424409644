<template>
  <div>
    <p class="error_msg" v-for="e in errors" :key="e">{{ e }}</p>
  </div>
</template>

<script>
export default {
  props: {
    errors: []
  }
}
</script>
